import { ApplicationsLayout } from "./../../../../../Layouts/ApplicationsLayout/ApplicationsLayout";
import { PageContainer } from "@components/webTechnology/PageTypes/UneditablePage/pages/applications/PageContainer";
import { SmartImage2 } from "@components/reusables/SmartImage/SmartImage2";
import { OtherPagesSwitch } from "../../../../../Layouts/ApplicationsLayout/components/OtherPagesSwitch/OtherPagesSwitch";
import { Content } from "@czechtv/components";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import {
  mediaMinDesktop1024,
  mediaMinTablet768,
  mediaMinTablet640,
  mediaMinMobile480,
  Body16Small,
  Body21Large,
  Body18Medium,
  Red_base,
  ButtonTransitionDuration,
  Grey_80,
  Grey_20,
} from "@czechtvPackagesOverrides/styles/fromCTV/index";
import { GRID_GAP, ANIMATION_BEZIER_HOVER } from "@consts/consts";
import {
  Hero48LargeMedium,
  Hero36MediumMedium,
  Header24LargeRegular,
} from "@czechtvPackagesOverrides/styles";
import { HeaderH1, HeaderH2, HeaderH3 } from "@czechtvPackagesOverrides/styles/utilities/headings";

import imageTop from "./images/aplikace_hbbtv_top_x2.png";
import logo from "./images/logo_ivysilani.svg";
import imgtv1 from "./images/tv_1_x2.png";
import imgtv2 from "./images/tv_2_x2.png";
import imgtv3 from "./images/tv_3_x2.png";
import imgtv4 from "./images/tv_4_x2.png";
import imgtv5 from "./images/tv_5_x2.png";
import imgtv6 from "./images/tv_6_x2.png";
import imgtv7 from "./images/tv_7_x2.png";
import imgtv8 from "./images/tv_8_x2.png";
import imgtv9 from "./images/tv_9_x2.png";
import imgtv10 from "./images/tv_10_x2.png";
import imgtv11 from "./images/tv_11_x2.png";
import imgtv12 from "./images/tv_12_x2.png";
import imgtv13 from "./images/tv_13_x2.png";
import imgtv14 from "./images/tv_14_x2.png";
import imgtv15 from "./images/tv_15_x2.png";

const useStyles = createUseStyles({
  headerContainer: {
    maxWidth: "1136px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  header: {
    margin: `${GRID_GAP * 3}px auto ${GRID_GAP * 4}px auto`,
    position: "relative",
    textAlign: "center",
    color: "#FFFF",
    display: "flex",
    flexDirection: "column",
    padding: `0px`,
    [mediaMinTablet640]: {
      margin: `${GRID_GAP * 6}px auto ${GRID_GAP * 10}px auto`,
    },
    "&::before": {
      position: "absolute",
      background: `radial-gradient(115% 115% at 50% 0%, #0A3368 0%, #001830 100%);`,
      content: '""',
      left: "-100vw",
      bottom: "23%",
      width: "300vw",
      height: "100vh",
      zIndex: "-1",
      "@media (min-width: 640px) and (max-height: 680px)": {
        height: "160vh",
      },
      "@media (min-width: 640px) and (max-height: 430px)": {
        height: "350vh",
      },
      "@media (min-width: 640px) and (max-height: 200px)": {
        height: "1050vh",
      },
      "@media (min-width: 640px) and (max-height: 100px)": {
        height: "10000vh",
      },
    },
    "& h1": {
      ...HeaderH1,
      color: `#FFFFFF`,
      margin: `0px auto ${GRID_GAP * 2}px auto`,
      padding: `0px ${GRID_GAP * 2}px`,
      order: "1",
      maxWidth: "800px",
      "& span": {
        ...Header24LargeRegular,
        display: "block",
        [mediaMinTablet640]: {
          ...Hero36MediumMedium,
        },
        [mediaMinTablet768]: {
          ...Hero48LargeMedium,
        },
      },
    },
  },
  headerTxt: {
    maxWidth: "670px",
    margin: `${GRID_GAP * 2}px auto ${GRID_GAP * 4}px auto`,
    padding: `0px ${GRID_GAP * 2}px`,
    order: "2",
    ...Body18Medium,
    lineHeight: "1.628rem",
    [mediaMinTablet640]: {
      margin: `0px auto ${GRID_GAP * 7}px auto`,
    },
    "& a": {
      color: "#FFF",
      textDecoration: "underline",
      transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    },
    "& a:hover": {
      color: Red_base,
      transition: `all ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    },
  },
  headerImage: {
    order: "3",
    [mediaMinTablet640]: {
      padding: `0px ${GRID_GAP * 3}px`,
    },
    [mediaMinDesktop1024]: {
      padding: `0px ${GRID_GAP * 4}px`,
    },
    "@media (min-width: 1136px)": {
      padding: `0px ${GRID_GAP * 0}px`,
    },
    "& img": {
      width: "110% !important",
      maxWidth: "110%  !important",
      transform: "translateX(-5%)",
      [mediaMinTablet640]: {
        transform: "translateX(0%)",
        width: "1136px  !important",
        maxWidth: "100%  !important",
      },
    },
  },
  infoTop: {
    width: `100%`,
    maxWidth: `640px`,
    textAlign: "center",
    margin: `${GRID_GAP * 0}px auto ${GRID_GAP * 4}px auto`,
    [mediaMinTablet640]: {
      margin: `${GRID_GAP * 0}px auto ${GRID_GAP * 6}px auto`,
    },
    [mediaMinDesktop1024]: {
      margin: `${GRID_GAP * 0}px auto ${GRID_GAP * 8}px auto`,
    },
    "& h2": {
      ...HeaderH2,
      textAlign: "center",
      "& span": {
        color: Red_base,
      },
    },
  },
  info1_3: {
    display: "flex",
    flexWrap: "wrap",
    gap: `${GRID_GAP * 4}px`,
    paddingBottom: `${GRID_GAP * 8}px`,
    borderBottom: `1px solid ${Grey_20}`,
    [mediaMinTablet768]: {
      flexWrap: "nowrap",
      paddingBottom: `${GRID_GAP * 11}px`,
    },
    [mediaMinDesktop1024]: {
      paddingBottom: `${GRID_GAP * 15}px`,
    },
    "& div": {
      textAlign: "center",
      width: "100%",
      color: Grey_80,
      ...Body16Small,
      [mediaMinTablet768]: {
        ...Body18Medium,
        flex: "1",
      },
    },
    "& h3": {
      ...HeaderH3,
      margin: `0px 0px ${GRID_GAP * 2}px 0px`,
    },
  },
  gridHead: {
    maxWidth: "640px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginBottom: `${GRID_GAP * 5}px`,
    "& h2": HeaderH2,
    "& p": {
      color: Grey_80,
      ...Body18Medium,
      margin: `0px 0px ${GRID_GAP * 2}px 0px`,
      [mediaMinTablet640]: {
        ...Body21Large,
      },
    },
  },
  gridHeadLogo: {
    margin: `${GRID_GAP * 5}px 0px`,
    "& img": {
      marginLeft: `auto`,
      marginRight: `auto`,
    },
  },
  grid: {
    display: "grid",
    marginBottom: `${GRID_GAP * 8}px`,
    gridTemplateColumns: "1fr",
    gap: `${GRID_GAP * 5}px ${GRID_GAP * 4}px`,
    [mediaMinMobile480]: {
      gridTemplateColumns: "1fr 1fr",
      gap: `${GRID_GAP * 6}px ${GRID_GAP * 2}px`,
    },
    [mediaMinTablet640]: {
      marginBottom: `${GRID_GAP * 11}px`,
      gap: `${GRID_GAP * 6}px ${GRID_GAP * 3}px`,
    },
    [mediaMinTablet768]: {
      gridTemplateColumns: "1fr 1fr  1fr",
      gap: `${GRID_GAP * 6}px ${GRID_GAP * 4}px `,
    },
    [mediaMinDesktop1024]: {
      marginBottom: `${GRID_GAP * 15}px`,
    },
    "& div": {
      textAlign: "center",
      color: Grey_80,
      ...Body16Small,
      [mediaMinTablet640]: {
        ...Body18Medium,
      },
    },
    "& img": {
      width: "80% !important",
      maxWidth: "80% !important",
      minWidth: "80% !important",
      [mediaMinTablet640]: {
        width: "100%",
        maxWidth: "352px !important",
        minWidth: "100% !important",
      },
    },
    "& div h3": {
      ...HeaderH3,
      textAlign: "center",
      margin: `0`,
      padding: `20px 0 0 0`,
      color: Grey_80,
    },
  },
});

export const ApplicationsHbbTVPage: React.FC = () => {
  const classes = useStyles();
  return (
    <PageContainer
      title="Služba přes červené tlačítko (HbbTV)"
      description="Stačí stisknout červené tlačítko na vašem ovladači a při sledování jakéhokoli programu ČT se objeví rozcestník aplikací pro sledování TV online."
    >
      <ApplicationsLayout>
        <main>
          <section className={classNames(classes.headerContainer)}>
            <div className={classNames(classes.header)}>
              <h1>
                Služba přes červené tlačítko <span>(HbbTV)</span>
              </h1>
              <p className={classNames(classes.headerTxt)}>
                Stačí stisknout červené tlačítko na vašem ovladači při sledování jakéhokoli programu
                České televize a objeví se před vámi rozcestník aplikací, ze kterých si každý vybere
                ty, kterého ho zajímají.
              </p>
              <div className={classNames(classes.headerImage)}>
                <SmartImage2
                  src={imageTop.src}
                  alt="Televizní obrazovka HBBTV"
                  width={1136}
                  height={376}
                />
              </div>
            </div>
          </section>
          <Content>
            <article>
              <div className={classNames(classes.infoTop)}>
                <h2>
                  HbbTV se rovná <span>červené tlačítko</span>
                </h2>
                Zkratka HbbTV znamená Hybrid broadcast broadband TV. V překladu je to televizor,
                který v sobě spojuje televizní a internetové vysílání. Ve zkratce je to odborný
                název pro technologii tzv. červeného tlačítka.
              </div>
              <div className={classNames(classes.info1_3)}>
                <div>
                  <h3>Červené tlačítko má každá Smart TV</h3>
                  Výhodou této technologie je, že je univerzální a podporují ji všichni výrobci
                  chytrých televizorů (tzv. Smart TV), teda televizorů s možností připojení
                  k internetu. To je také hlavní rozdíl mezi HbbTV a Smart TV. Různé značky
                  televizorů podporují různé systémy, ale všechny používají jednotné HbbTV.
                </div>
                <div>
                  <h3>Aplikace pod červeným tlačítkem nemusíte instalovat</h3>
                  Stačí mít televizor připojen k internetu a HbbTV je ihned aktivní. Poznáte to
                  podle toho, že se vám na obrazovce ukáže červené tlačítko. Výjimkou mohou být
                  televizory Samsung, u kterých je nutná aktivace ze strany uživatele.
                </div>
                <div>
                  <h3>K HbbTV nestačí mít internet</h3>
                  Je třeba přijímat televizní programy z distribuce, ve které je dostupná
                  signalizace HbbTV. To bohužel není všude. Garantujeme vám dostupnost v pozemní
                  distribuci DVB-T2 a na satelitu (družice Astra 3B, pozice 23,5° E). Najdete ho ale
                  i v některých dalších distribucích.
                </div>
              </div>
            </article>
            <article>
              <header className={classNames(classes.gridHead)}>
                <div className={classNames(classes.gridHeadLogo)}>
                  <SmartImage2 src={logo.src} alt="iVysilání" width={157} height={68} />
                </div>
                <h2>Jednoduchá, pohodlná a přehledná aplikace iVysílání</h2>
                <p>
                  Stěžejní aplikace iVysílání ČT nabízí moderní design a celou řadu skvělých funkcí.
                </p>
              </header>
              <div className={classNames(classes.grid)}>
                <div>
                  <SmartImage2 width={352} height={226} src={imgtv1.src} alt="iVysilání - Výběry" />
                  <h3>Výběry</h3>
                  Přehledné členěná nabídka, exkluzivní živé internetové vysílání a obsah, který
                  jinde nenajdete.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={227}
                    src={imgtv2.src}
                    alt="iVysilání - Detail pořadu"
                  />
                  <h3>Detail pořadu</h3>
                  Stránky věnované jednotlivým pořadům spolu s jejich podrobným popisem a bonusovými
                  videy.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={226}
                    src={imgtv3.src}
                    alt="iVysilání - Kategorie"
                  />
                  <h3>Kategorie</h3>
                  Členění všech pořadů do kategorií podle konkrétních žánrů a typů.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={226}
                    src={imgtv4.src}
                    alt="iVysilání - Program vysílání"
                  />
                  <h3>Program vysílání</h3>
                  Televizní program všech kanálů ČT spolu s nabídkou speciálních přenosů.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={227}
                    src={imgtv5.src}
                    alt="iVysilání - Hledání"
                  />
                  <h3>Hledání</h3>
                  Fulltextové vyhledávání pořadů nebo třeba konkrétních dílů.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={226}
                    src={imgtv6.src}
                    alt="iVysilání - Moje pořady"
                  />
                  <h3>Moje pořady</h3>
                  Zde najdete vaše oblíbené pořady včetně těch rozkoukaných, v jejichž sledování
                  můžete snadno pokračovat.
                </div>
              </div>
            </article>

            <article>
              <header className={classNames(classes.gridHead)}>
                <h2>Červené tlačítko toho umí ještě víc</h2>
                <p>Tady je pár dalších aplikací, které najdete v naší nabídce.</p>
              </header>
              <div className={classNames(classes.grid)}>
                <div>
                  <SmartImage2
                    width={352}
                    height={226}
                    src={imgtv7.src}
                    alt="iVysilání - iVysílání :D"
                  />
                  <h3>iVysílání :D</h3>
                  Zábava pro malé diváky. Archivní dětské pořady přehledně na jednom místě.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={227}
                    src={imgtv8.src}
                    alt="iVysilání - TV program"
                  />
                  <h3>TV program</h3>
                  Program všech kanálů ČT na 14 dní, týden dopředu a týden nazpět. Spolu s přístupem
                  k archivnímu obsahu a popisem pořadů.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={226}
                    src={imgtv9.src}
                    alt="iVysilání - Teletext"
                  />
                  <h3>Teletext</h3>
                  Kdo by neznal teletext. Tady ho naleznete v moderní grafice a s jednodušším
                  ovládáním.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={227}
                    src={imgtv10.src}
                    alt="iVysilání - Počasí"
                  />
                  <h3>Počasí</h3>
                  Předpověď pro Česko na týden dopředu. Možnost výběru konkrétních míst. Animovaný
                  radar.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={227}
                    src={imgtv11.src}
                    alt="iVysilání - Panorama"
                  />
                  <h3>Panorama</h3>
                  Informační servis z horských středisek v Česku včetně záběrů z panoramatických
                  kamer.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={227}
                    src={imgtv12.src}
                    alt="iVysilání - ČT sport Plus"
                  />
                  <h3>ČT sport Plus</h3>
                  Celá řada živých sportovních přenosů, a to výhradně z internetové distribuce.
                  Jednoduše a rychle dostupné stisknutím zeleného tlačítka.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={227}
                    src={imgtv13.src}
                    alt="iVysilání - Významné sportovní události"
                  />
                  <h3>Významné sportovní události</h3>
                  Pravidelné aplikace z hokejových a fotbalových šampionátů. Zahrnují živé přenosy,
                  video archiv, online textové přenosy, soupisky, tabulky a další statistiky.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={227}
                    src={imgtv14.src}
                    alt="iVysilání - Extraliga"
                  />
                  <h3>Extraliga</h3>
                  Podrobné online statistiky ze všech zápasů naší nejvyšší soutěže. Průběžné
                  výsledky s možností vložení do obrazu, přístup k dlouhodobým statistikám atd.
                </div>
                <div>
                  <SmartImage2
                    width={352}
                    height={227}
                    src={imgtv15.src}
                    alt="iVysilání - Významné televizní pořady"
                  />
                  <h3>Významné televizní pořady</h3>
                  Občas najdete v nabídce i aplikace ve vazbě na televizní pořad. Patří sem např.
                  Taneční škola StarDance, kvízová aplikace Nejchytřejší Čech nebo aplikace Peče
                  celá země, ve které jsme nabídli všechny recepty publikované v pořadu.
                </div>
              </div>
            </article>
          </Content>
          <OtherPagesSwitch linksTo={["toMobile", "toSmartTv"]} />
        </main>
      </ApplicationsLayout>
    </PageContainer>
  );
};
