import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { SmartImage2 } from '@components/reusables/SmartImage/SmartImage2';
import Link from 'next/link';
import {
  mediaMinDesktop1024,
  mediaMinTablet768,
  mediaMinTablet640,
  mediaMinMobile480,
  Red_base,
  Grey_80,
  Grey_20,
  Body21Large,
  Body18Medium,
  Hero36Medium,
  ButtonTransitionDuration,
  Blue_base,
} from '@czechtvPackagesOverrides/styles/fromCTV/index';
import { buttonPrimary } from '@czechtvPackagesOverrides/styles/utilities/buttonPrimary'
import { GRID_GAP, ANIMATION_BEZIER_HOVER } from '@consts/consts';
import { PAGES } from './../../applicationsGlobalMenuConfig'
import imgApp from './images/box_aplikace_x2.png'
import imgHbbtv from './images/box_hbbtv_x2.png'
import imgIvys from './images/box_ivysilani_x2.png'
import { HeaderH2, HeaderH3 } from '@czechtvPackagesOverrides/styles/utilities/headings'


const unifiedContainer = {
  maxWidth: '1136px',
  marginLeft: `${GRID_GAP * 2}px`,
  marginRight: `${GRID_GAP * 2}px`,
  paddingLeft: `${GRID_GAP * 2}px`,
  paddingRight: `${GRID_GAP * 2}px`,
  [mediaMinTablet640]: {
    paddingLeft: `${GRID_GAP * 3}px`,
    paddingRight: `${GRID_GAP * 3}px`,
    marginLeft: `${GRID_GAP * 3}px !important`,
    marginRight: `${GRID_GAP * 3}px !important`,
  },
  [mediaMinDesktop1024]: {
    paddingLeft: `${GRID_GAP * 4}px`,
    paddingRight: `${GRID_GAP * 4}px`,
    marginLeft: `${GRID_GAP * 4}px !important`,
    marginRight: `${GRID_GAP * 4}px !important`,
  },
  '@media (min-width: 1136px)': {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}


const useStyles = createUseStyles({
  containerHeader: {
    maxWidth: '1136px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& h2': {
      margin: `0px auto ${GRID_GAP * 2}px auto`,
      padding: `0px ${GRID_GAP * 2}px`,
      maxWidth: `400px`,
      textAlign: `center`,
      color: Blue_base,
      ...Hero36Medium,
      [mediaMinTablet640]: {
        ...Hero36Medium,
      },
    },
  },
  contact: {
    ...unifiedContainer,
    borderTop: `1px solid ${Grey_20}`,
    borderBottom: `1px solid ${Grey_20}`,
    paddingTop: `${GRID_GAP * 4}px`,
    paddingBottom: `${GRID_GAP * 4}px`,
    marginBottom: `${GRID_GAP * 8}px`,
    color: Grey_80,
    ...Body18Medium,
    [mediaMinTablet640]: {
      ...Body21Large,
      paddingTop: `${GRID_GAP * 6}px`,
      paddingBottom: `${GRID_GAP * 6}px`,
      marginBottom: `${GRID_GAP * 12}px`,
    },
    [mediaMinDesktop1024]: {
      paddingTop: `${GRID_GAP * 8}px`,
      paddingBottom: `${GRID_GAP * 8}px`,
      marginBottom: `${GRID_GAP * 15}px`,
    },

    '& p.subHead': {
      margin: `0px auto ${GRID_GAP * 1}px auto`,
      fontWeight: 'normal',
      textAlign: `center`,
      color: Grey_80,
      ...Body21Large,
    },
    '& p': {
      margin: `0px auto 0px auto`,
      width: '100%',
      maxWidth: `640px`,
      textAlign: `center`,
      color: Grey_80,
    },
    '& p a': {
      textDecoration: 'underline',
      color: Blue_base,
    },
    '& h2': {
      ...HeaderH2,
      margin: `0px auto ${GRID_GAP * 2}px auto`,
      maxWidth: `400px`,
      textAlign: `center`,
    },
  },
  contactIn: {
    display: 'flex',
    gap: `${GRID_GAP * 2}px`,
    marginBottom: `${GRID_GAP * 4}px`,
    justifyContent: `center`,
    flexWrap: 'wrap',
    [mediaMinTablet640]: {
      gap: `${GRID_GAP * 4}px`,
      flexWrap: 'nowrap',
      marginBottom: `${GRID_GAP * 3}px`,
    },
  },
  contactTel: {
    width: '100%',
    display: 'block',
    textDecoration: 'none',
    color: Grey_80,
    paddingTop: `${GRID_GAP * 5}px`,
    transition: `color ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjYyODQ5IDI2LjM3MDhDMTEuMzU2NiAzMi4wOTg4IDIwLjY0MjMgMzIuMDk4OCAyNi4zNzA1IDI2LjM3MDhDMzIuMDk4NiAyMC42NDI5IDMyLjA5ODYgMTEuMzU3NSAyNi4zNzA1IDUuNjI5NDhDMjAuNjQyMyAtMC4wOTg0OTk4IDExLjM1NjYgLTAuMDk4NDk5OCA1LjYyODQ5IDUuNjI5NDhDLTAuMDk4NDkzNiAxMS4zNTc1IC0wLjA5ODQ5MzYgMjAuNjQyOSA1LjYyODQ5IDI2LjM3MDhaTTEzLjUwMzYgOS45MDI5N0MxMy45MzgzIDEwLjMzNjYgMTQuMDMwNiAxMS4wMDY5IDEzLjcyOTEgMTEuNTQyTDEyLjUxMjMgMTMuMzg4N0MxMi4zMTA3IDEzLjc0NTIgMTIuMzcyMyAxNC4xOTIxIDEyLjY2MyAxNC40ODFMMTcuNTAyMyAxOS4zMDg0QzE3Ljc5MjIgMTkuNTk4IDE4LjI0MDEgMTkuNjU5MSAxOC41OTczIDE5LjQ1NzdMMjAuNDQ3NSAxOC4yNDI4QzIwLjk4MzcgMTcuOTQxMyAyMS42NTU3IDE4LjAzMzMgMjIuMDkwNiAxOC40Njc4TDI0LjI2NTQgMjAuNjM3MkMyNC44MDAzIDIxLjE3MTkgMjQuODAwMyAyMi4wMzc3IDI0LjI2NTQgMjIuNTcyNEwyMy4yMzU4IDIzLjU5OTRDMjIuMDYxNSAyNC43NjgxIDIwLjI0NjYgMjUuMDAzOCAxOC44MTE1IDI0LjE3NDJMMTguNzEzMyAyNC4xMTgyQzE0LjQ0NjggMjEuNjg2MyAxMC44NzgyIDE4LjIwMTYgOC4zNSAxMy45OTgzTDcuODU1NjggMTMuMTc1MkM2Ljk5MTEzIDExLjc0MDMgNy4yMTcxNSA5LjkwMjQ4IDguNDAzNzYgOC43MTg1Nkw5LjM5MDA3IDcuNzM0NjZDOS42NDcyIDcuNDc3ODIgOS45OTYxNCA3LjMzMzUgMTAuMzYgNy4zMzM1QzEwLjcyMzkgNy4zMzM1IDExLjA3MjggNy40Nzc4MiAxMS4zMyA3LjczNDY2TDEzLjUwMzYgOS45MDI5N1oiIGZpbGw9IiMxQjYwQkIiLz4KPC9zdmc+Cg==)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top 3px',
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'none',
      color: Blue_base,
      transition: `color ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    },
    [mediaMinTablet640]: {
      width: '220px',
    },
  },
  contactMail: {
    width: '100%',
    display: 'block',
    textDecoration: 'none',
    color: Grey_80,
    paddingTop: `${GRID_GAP * 5}px`,
    transition: `color ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjYyOTM0IDUuNjI5NDhDMTEuMzU3MyAtMC4wOTg0OTk4IDIwLjY0MjcgLTAuMDk4NDk5OCAyNi4zNzA3IDUuNjI5NDhDMzIuMDk4NyAxMS4zNTc1IDMyLjA5ODcgMjAuNjQyOSAyNi4zNzA3IDI2LjM3MDhDMjAuNjQyNyAzMi4wOTg4IDExLjM1NzMgMzIuMDk4OCA1LjYyOTM0IDI2LjM3MDhDLTAuMDk3NDc0MSAyMC42NDI5IC0wLjA5NzQ3NDEgMTEuMzU3NSA1LjYyOTM0IDUuNjI5NDhaTTI1LjMzMzQgMTQuMDAwMkwxNi41OTYzIDE4LjM2ODdMMTYuNDMxOCAxOC40Mzc2QzE2LjE1MiAxOC41MzM0IDE1Ljg0ODEgMTguNTMzNCAxNS41NjgzIDE4LjQzNzZMMTUuNDAzOCAxOC4zNjg3TDYuNjY2NzIgMTQuMDAwMlYyMC41MzM1QzYuNjY2NzIgMjEuNjQ2MiA3LjUxODY3IDIyLjU2IDguNjA1ODggMjIuNjU4MUw4LjgwMDA2IDIyLjY2NjhIMjMuMjAwMUMyNC4zMTI4IDIyLjY2NjggMjUuMjI2NiAyMS44MTQ5IDI1LjMyNDcgMjAuNzI3N0wyNS4zMzM0IDIwLjUzMzVWMTQuMDAwMlpNMjMuMjAwMSA5LjMzMzVIOC44MDAwNkM3LjY4NzMgOS4zMzM1IDYuNzczNTQgMTAuMTg1NCA2LjY3NTQ0IDExLjI3MjdMNi42NjY3MiAxMS40NjY4VjEyLjMzMzVMMTUuNDAzOCAxNi43MDJMMTUuNTY4MyAxNi43NzFDMTUuODQ4MSAxNi44NjY3IDE2LjE1MiAxNi44NjY3IDE2LjQzMTggMTYuNzcxTDE2LjU5NjMgMTYuNzAyTDI1LjMzMzQgMTIuMzMzNVYxMS40NjY4QzI1LjMzMzQgMTAuMzU0MSAyNC40ODE0IDkuNDQwMzEgMjMuMzk0MiA5LjM0MjIxTDIzLjIwMDEgOS4zMzM1WiIgZmlsbD0iIzFCNjBCQiIvPgo8L3N2Zz4K)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top 3px',
    textAlign: 'center',
    '&:hover': {
      textDecoration: 'none',
      color: Blue_base,
      transition: `color ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
    },
    [mediaMinTablet640]: {
      width: '220px',
    },
  },
  boxes: {
    display: 'flex',
    gap: `${GRID_GAP * 6}px`,
    flexWrap: 'wrap',
    marginBottom: `${GRID_GAP * 7}px`,
    [mediaMinTablet640]: {
      gap: `${GRID_GAP * 0}px`,
      flexWrap: 'nowrap',
      marginBottom: `${GRID_GAP * 10}px`,
    },
    [mediaMinTablet768]: {
      marginBottom: `${GRID_GAP * 12}px`,
    },
    [mediaMinDesktop1024]: {
      marginBottom: `${GRID_GAP * 15}px`,
    }
  },
  box: {
    flex: 'none',
    boxSizing: 'border-box',
    textAlign: 'center',
    width: '100%',
    padding: `0px ${GRID_GAP * 2}px`,
    [mediaMinTablet640]: {
      flex: '1',
      padding: `0px ${GRID_GAP * 3}px`,
    },
    [mediaMinDesktop1024]: {
      padding: `0px ${GRID_GAP * 4}px`,
    },
    '& a:hover': {
      textDecoration: 'none',
    },
    '& p': {
      margin: `${GRID_GAP * 0}px 0px ${GRID_GAP * 1}px 0px`,
      [mediaMinTablet640]: {
        margin: `${GRID_GAP * 0}px 0px ${GRID_GAP * 3}px 0px`,
      },
    },
    '& h3': {
      ...HeaderH3,
      textAlign: 'center',
      padding: `0px ${GRID_GAP * 2}px`,
      margin: `${GRID_GAP * 2}px 0px ${GRID_GAP * 1}px 0px`,
      [mediaMinTablet640]: {
        margin: `${GRID_GAP * 3}px 0px ${GRID_GAP * 1}px 0px`,
        padding: `0px ${GRID_GAP * 0}px`,
      },
      '& span': {
        color: Red_base,
      }
    }
  },
  boxNoPadd: {
    padding: `0px 0px !important`,
    [mediaMinMobile480]: {
      padding: `0px ${GRID_GAP * 3}px  !important`,
    },
    [mediaMinDesktop1024]: {
      padding: `0px ${GRID_GAP * 4}px  !important`,
    },
    '& p': {
      padding: `0px ${GRID_GAP * 2}px`,
      [mediaMinMobile480]: {
        padding: `0px ${GRID_GAP * 0}px`,
      },
    },
    '& img': {
      width: `120% !important`,
      maxWidth: `120% !important`,
      transform: 'translateX(-10%)',
      [mediaMinMobile480]: {
        maxWidth: `100% !important`,
        transform: 'translateX(0%)',
      },
    }
  },
  linkLikeButton: {
    ...buttonPrimary,
  }
});


export const OtherPagesSwitch = ({ linksTo }: { linksTo: any[] }) => {
  const classes = useStyles();

  const isToSmartTv = linksTo.includes('toSmartTv');
  const isToHbbTv = linksTo.includes('toHbbTv');
  const isToMobile = linksTo.includes('toMobile');

  return (
    <section className={classNames(classes.containerHeader)}>

      <div className={classNames(classes.contact)}>
        <h2>Máte nějaký dotaz? Potřebujete poradit?</h2>
        <p className='subHead'>Kontaktujte nás na</p>
        <div className={classNames(classes.contactIn)}>
          <a href="tel:+420261136113" className={classNames(classes.contactTel)}>+420 261 136 113</a>
          <a href="mailto:info@ceskatelevize.cz" className={classNames(classes.contactMail)}>info@ceskatelevize.cz</a>
        </div>
        <p>Chcete pomoct s pozemním, internetovým vysíláním nebo iVysíláním? Navštivte web <a href="https://www.ceskatelevize.cz/vse-o-ct/">Vše o ČT</a>.</p>
      </div>

      <h2 >Naše další aplikace</h2>
      <div className={classNames(classes.boxes)}>
        {isToMobile ? (
          <div className={classNames(classes.box)}>
            <SmartImage2
              src={imgApp.src}
              alt="ČT Aplikace"
              width={504}
              height={223}
            />
            <h3>Mobilní aplikace</h3>
            <p>Sledujte pohodlně pořady ČT v aplikaci iVysílání, procházejte nejnovější zprávy v aplikaci ČT24 a nenechte si ujít žádné sportovní utkání díky aplikaci ČT Sport. A na nejmenší uživatele tu čeká hned v několika aplikacích obsah z jejich oblíbeného Déčka.</p>

            <Link
              href={PAGES.applicationsMobilePage.url}
              className={classNames(classes.linkLikeButton)}>
              Více o mobilních aplikacích
            </Link>
          </div>
        ) : null}
        {isToHbbTv ? (
          <div className={classNames(classes.box, classes.boxNoPadd)}>
            <SmartImage2
              src={imgHbbtv.src}
              width={504}
              height={215}
              alt="ČT HBBTV"
            />
            <h3>Služba přes <span>červené tlačítko</span> (HbbTV)</h3>
            <p>Máte doma chytrý televizor, který je připojen k internetu a podporuje technologii HbbTV? Zjistěte, jak ho využít naplno a seznamte se s užitečnými službami dostupnými přes červené tlačítko.</p>
            <Link
              href={PAGES.applicationsHbbTVPage.url}
              className={classNames(classes.linkLikeButton)}>
              Více o červeném tlačítku
            </Link>
          </div>
        ) : null}
        {isToSmartTv ? (
          <div className={classNames(classes.box)}>
            <SmartImage2
              src={imgIvys.src}
              width={504}
              height={219}
              alt="ČT iVysilanii"
            />
            <h3>iVysílání v chytrých televizorech </h3>
            <p>Prostřednictvím vylepšené aplikace iVysílání, kterou podporuje řada chytrých televizorů, máte možnost sledovat živé vysílání i archiv ČT bez nutnosti přijímat televizní signál.</p>
            <Link
              href={PAGES.applicationsSmartTVPage.url}
              className={classNames(classes.linkLikeButton)}>
              Více otelevizních aplikacích
            </Link>
          </div>
        ) : null}
      </div>
    </section>
  );
}
